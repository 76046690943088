<template>
 <v-container>

    <div>
            <v-card class="" elevation="" max-width="" >
                <v-card-title primary-title>
                    Contato Plantão Hybrid ( Serviço de Hospedagem dos servidores)
                </v-card-title>

                <div style="display:flex;width:100%;max-width:100%;">
                    <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">
                        <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                            <h4>Plantão</h4>
                            <div >Contato Whatsapp: 84 98152-0290</div>
                        </div>
        
                    </div>
                </div>

            </v-card>
    </div>



    <div  style="margin-top:20px;">
            <v-card class="" elevation="" max-width="" >
                <v-card-title primary-title>
                    Contato App Sistemas 
                </v-card-title>

                <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">

                    <div style="display:flex;">
                        <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">
                            <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                                <h4>Kamila Barata ( Parte Tecnica, desenvolvimento )</h4>
                                <div >Contato Whatsapp: 17 99149-4496</div>
                            </div>
            
                        </div>
                    </div>

                    <div style="display:flex;">
                        <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">
                            <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                                <h4>Patricia ( Sucesso do Cliente )</h4>
                                <div >Contato Whatsapp: 17 99252-3581</div>
                            </div>
            
                        </div>
                    </div>

                </div>
            </v-card>
    </div>
   

   <div  style="margin-top:20px;">
            <v-card class="" elevation="" max-width="" >
                <v-card-title primary-title>
                    Contato ADMH
                </v-card-title>

                <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">

                    <div style="display:flex;">
                        <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">
                            <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                                <h4>Robert (Proprietario e Dev )</h4>
                                <div >Contato Whatsapp: 73 99107-5255</div>
                            </div>
            
                        </div>
                    </div>

                    

                </div>
            </v-card>
    </div>

   <div  style="margin-top:20px;">
            <v-card class="" elevation="" max-width="" >
                <v-card-title primary-title>
                    Contato Erbon
                </v-card-title>

                <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">

                    <div style="display:flex;">
                        <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">
                            <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                                <h4>Giovanni Neto</h4>
                                <div >Contato Whatsapp: 11 99143-0707</div>
                                <div >Email: giovannineto@erbonsoftware.com.br</div>
                            </div>
            
                        </div>
                    </div>

                    

                </div>
            </v-card>
    </div>


  </v-container>
</template>

<script>
// import DadosSistemas from '@/components/Cliente/DadosSistemas'
export default {
//   components: {DadosSistemas},
  data(){
    return{}
  }
}
</script>

<style>

</style>